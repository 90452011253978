/*
 * Script.js
 */
require('ods-tool-kit/dist/js/script.js')
$(function () {
  slideMenuAomori();
  smoothScroll();
  setSliderAomori();
  innnerHeightAlign();
  setFormPosition();
  $('[data-toggle="tooltip"]').tooltip();

  $(window).on('resize', function () {
    slideMenuAomori();
    smoothScroll();
    innnerHeightAlign();
  });

  $(window).on('scroll', function () {
  });

});

/*
    サブメニューのアコーディオン非表示
====================================*/
function slideMenuAomori() {
  $('.header-menu > li > a:not(.btn-close)').off();
  $('.header-menu > li > a:not(.btn-close)').on('click', function() {
    if ($('.header-menu').css('display') !== 'flex') {
      $('html').removeClass('mobile-menu-open');
      $('header nav').animate({right: '-280px'}, 500, 'swing');
      $(this).removeClass('active');
      // hrefがアンカー（#から始まっている場合）はリンク無効
      if ($(this).attr('href').startsWith('#')) {
        return false;
      }
    }
  });
}

/*
    スムーススクロール
====================================*/
function smoothScroll() {
  $('a[href^="#"]:not([href^="#tab"]):not([href^="#close"])').click(function() {
    var href = $(this).attr('href');
    var target = $(href == '#' || href == '' ? 'html' : href);

    var tab = $(this).attr('role');
    var tooltip = $(this).attr('data-toggle');
    if (!tab) {
      if (tooltip != 'tooltip' && href != '#none') {
        var position = target.offset().top;
        $('html, body').animate({scrollTop: position}, 500, 'swing');
      }
      return false;
    }
  });

  var hashString = location.hash.substr(1);
  if (hashString !== "" && $('#'+hashString).length) {
    var position = $(location.hash).offset().top;
    $('html, body').animate({ scrollTop: position }, 1);

    history.replaceState('', document.title, window.location.pathname);

    return false;
  }

  if (!$('.nav-link.active').length) {
    $('.nav.nav-tabs.index-nav-tabs .nav-item:first-child a').trigger('click');
  }
}

/*
    FVのスライダー
====================================*/
function setSliderAomori() {
  if ($("#slider")[0]) {
    var flag = false;

    // 先にスケールのみクラス追加
    $('.slide-item:first-child').addClass('is-scaling');

    // slickスライダー初期化
    $('#slider').slick({
      dots: false,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: true,
      prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" onclick="gtag(\'event\', \'click\', {\'event_category\' : \'Slide Arrow\', \'event_label\' : \'Prev\'});">Previous</button>',
      nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" onclick="gtag(\'event\', \'click\', {\'event_category\' : \'Slide Arrow\', \'event_label\' : \'Next\'});">Next</button>',
      lazyLoad: 'ondemand',
      fade: true,
      infinite: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            arrows: false
          }
        }
      ]
    });

    // 1秒後にスライダー設定、テキストアニメーションの実行
    setTimeout(function() {
      flag = true;
      $('.slide-item').removeClass('is-text-animation');
      $('.slick-slide:first-child .slide-item').addClass('is-text-animation');
    },1000);

    // スライドが切り替わったらスケールとアニメーションのクラス追加
    $('.slick-slide').addedClass('slick-active', function() {
      if (flag) {
        var active_no = $('.slick-slide.slick-active').index('.slick-slide') + 1;
        $('.slick-slide:nth-child('+active_no+') .slide-item').addClass('is-scaling is-text-animation');
        // 切り替わって0.5秒後に前のスライドのクラスを削除
        setTimeout(function() {
          $('.slick-slide:not(:nth-child('+active_no+')) .slide-item').removeClass('is-scaling is-text-animation');
        },500);
      }
    });
  }
}

/*
    layout-style-i の高さを揃える
====================================*/
function innnerHeightAlign() {
  if ($('.layout-style-i')[0]) {
    // 元のスタイルを削除
    $('.layout-style-i .inner').css('height', '');

    var bp = Number(getBreakpoint());
    if (bp >= 768) {
      // .layout-style-i の個数分回す
      $('.layout-style-i').each(function(i, box) {
        var maxHeight = 0;
        // inner の個数分回す
        $(box).find('.inner').each(function() {
          // もし、高さが高い場合は、maxサイズを更新
          if ($(this).height() > maxHeight) maxHeight = $(this).height();
        });
        // 最大値で高さを設定
        $(box).find('.inner').height(maxHeight);
      });
    }
  }
}

function setFormPosition() {
  if ($('.wpcf7-form')[0]) {

    // レスポンスエリアは基本非表示
    if ($('.wpcf7-response-output.wpcf7-display-none')[0]) {
      $('.form-response').addClass('d-none');
    }

    // バリデーションエラーの場合
    if ($('.wpcf7-not-valid-tip')[0]) {
      $('.wpcf7-not-valid-tip').prev().addClass('validation-error');

      if ($('.select-box .wpcf7-not-valid-tip')[0]) {
        $('.select-box').addClass('validation-error');
      } else {
        $('.select-box').removeClass('validation-error');
      }
    }

    if ($('form.wpcf7-form').hasClass('sent')) {
      // サンキューページへリダイレクト
      var url = location.href;
      url = url.replace(/#.*/g, '').replace(/$/g, 'thanks');
      location = url;
    } else {
      // お問い合わせフォームの場合はフェードイン
      $('section.contact-form').fadeIn();
    }
  } else if ($('section.contact-form')[0]) {
    $('section.contact-form').fadeIn();
  }
}
